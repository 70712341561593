import { Divider, Flex } from 'antd';

import { PremiumTagIfOnePricing } from 'components/Tag/PremiumTagIfOnePricing';
import { Title } from 'components/Typography';

import { TabsContentContainer, CustomizationItem, ItemsContainer, PreviewContainer, StyledCollapse } from './styled';
import Preview from '../Preview';

interface ITabLayoutProps {
  items: {
    title: string;
    content: React.ReactNode;
    key: string;
    collapsible?: boolean;
  }[];
}

const TabTemplate = ({ items }: ITabLayoutProps) => {
  return (
    <TabsContentContainer vertical>
      <Flex>
        <ItemsContainer>
          {items.map((item, index) => (
            <div key={item.key}>
              {item.collapsible ? (
                <>
                  <StyledCollapse
                    bordered={false}
                    items={[
                      {
                        label: (
                          <Flex justify="space-between">
                            {item.title} <PremiumTagIfOnePricing />
                          </Flex>
                        ),
                        children: item.content,
                        key: item.key,
                      },
                    ]}
                    expandIconPosition="end"
                  />
                  <Divider />
                </>
              ) : (
                <>
                  <CustomizationItem gap={16} vertical>
                    <Flex justify="space-between">
                      <Title level={4} $noMargin>
                        {item.title}
                      </Title>
                      <PremiumTagIfOnePricing />
                    </Flex>
                    {item.content}
                  </CustomizationItem>
                  {index !== items.length - 1 && <Divider />}
                </>
              )}
            </div>
          ))}
        </ItemsContainer>
        <PreviewContainer vertical>
          <Preview />
        </PreviewContainer>
      </Flex>
    </TabsContentContainer>
  );
};

export default TabTemplate;
