import BackgroundOverlay from './BackgroundOverlay';
import { buttonColors, layoutColors, toggleColors } from './colorOptions';
import Colors from './Colors';
import TabTemplate from '../../TabTemplate';

const StylingTab = () => {
  return (
    <TabTemplate
      items={[
        {
          title: 'Background Overlay',
          key: 'backgroundOverlay',
          content: <BackgroundOverlay />,
        },
        {
          title: 'Button Colors',
          key: 'buttonColors',
          content: <Colors colors={buttonColors} />,
        },
        {
          title: 'Layout Colors',
          key: 'layoutColors',
          collapsible: true,
          content: <Colors colors={layoutColors} />,
        },
        {
          title: 'Toggle Colors',
          key: 'toggleColors',
          collapsible: true,
          content: <Colors colors={toggleColors} />,
        },
      ]}
    />
  );
};

export default StylingTab;
