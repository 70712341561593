import { Flex } from 'antd';
import styled from 'styled-components';

import Button from 'components/Button';

export const BannerContainer = styled(Flex)<{ $background?: string }>`
  background: ${(props) => props.$background};
  box-shadow: 0px 0px 8px 2px ${(props) => props.theme.colors.primaryText}0d;
  padding: 15px;
  width: 100%;
  height: 40%;
  max-height: 160px;
  position: absolute;
  bottom: -50px;
  left: 0;
`;

export const WallBanner = styled(Flex)<{ $background?: string }>`
  display: flex;
  flex-direction: column;
  gap: 16px;
  justify-content: center;
  padding: 20px;
  background: ${(props) => props.$background};
  box-shadow: 0px 0px 8px 2px ${(props) => props.theme.colors.primaryText}0d;
  border-radius: 8px;
  width: 60%;
  max-width: 625px;
  position: absolute;
  transform: translate(-50%, -50%);
  top: calc(50% + 50px);
  left: 50%;
`;

export const StyledLink = styled(Button)<{ $color?: string }>`
  font-size: 10px;
  color: ${(props) => props.$color};
  font-weight: 500;

  &&&:hover {
    color: ${(props) => props.$color};
    opacity: 0.6;
  }
`;
