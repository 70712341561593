import { Input, Divider, Typography } from 'antd';
import { memo } from 'react';

import { Item } from 'components/Form';
import { Text } from 'components/Typography';
import { domainRegexp } from 'lib/helpers/validation';

import { Collapse } from '../../styled';

const { Paragraph } = Typography;

function WebPlatform() {
  return (
    <>
      <Item
        label="Domain"
        name="domain"
        colon={false}
        weight="medium"
        rules={[
          { required: true, message: 'Required field' },
          { pattern: domainRegexp, message: 'Please enter a valid domain string' },
        ]}
        requiredMark={false}
      >
        <Input placeholder="mycompany.com" />
      </Item>
      <Divider style={{ margin: '16px 0' }} />
      <Collapse
        bordered={false}
        ghost
        expandIconPosition="end"
        items={[
          {
            key: '1',
            label: <Text weight={500}>How does the scan work?</Text>,
            children: (
              <Paragraph type="secondary">
                After scanning, all third-party services found on your domain will appear in Service Settings/DPS
                Scanner. To ensure compliance, include all legally relevant results in your consent banner.
              </Paragraph>
            ),
          },
        ]}
      />
    </>
  );
}

export default memo(WebPlatform);
