import { Radio, Flex, RadioChangeEvent } from 'antd';

import { LayoutDtoSecondLayerVariant, LegalFramework } from 'api/requests/generated/generated.schemas';
import { BannerCenter, BannerSide } from 'components/Icons';
import { Text } from 'components/Typography';
import useOnboarding from 'lib/hooks/useOnboarding';

import { BannerWrapper, StyledRadio } from './styled';

const secondLayerOptions = [
  {
    title: 'Privacy Settings',
    note: 'Center Placement',
    value: LayoutDtoSecondLayerVariant.CENTER,
    key: LayoutDtoSecondLayerVariant.CENTER,
    banner: (
      <BannerWrapper position="center">
        <BannerCenter />
      </BannerWrapper>
    ),
  },
  {
    title: 'Privacy Settings',
    value: LayoutDtoSecondLayerVariant.SIDE,
    key: LayoutDtoSecondLayerVariant.SIDE,
    note: 'Side Placement',
    banner: (
      <BannerWrapper position="left">
        <BannerSide />
      </BannerWrapper>
    ),
  },
];

const SecondLayer = () => {
  const { selectedFramework, customizationDetails, setCustomizationDetails } = useOnboarding();

  const isTcf = selectedFramework === LegalFramework.TCF2;

  const options = isTcf
    ? secondLayerOptions.filter((option) => option.value !== LayoutDtoSecondLayerVariant.SIDE)
    : secondLayerOptions;

  const onChange = (e: RadioChangeEvent) => {
    setCustomizationDetails((prev) => ({
      ...prev,
      layout: { ...prev.layout, secondLayerVariant: e.target.value },
    }));
  };

  return (
    <Radio.Group value={customizationDetails.layout.secondLayerVariant}>
      <Flex vertical gap={8}>
        {options.map(({ banner, key, note, title, value }) => (
          <StyledRadio value={value} key={key} onChange={onChange}>
            {banner}
            <Flex vertical>
              <Text weight={500}>{title}</Text>
              <Text weight={400} size="sm" type="secondary">
                {note}
              </Text>
            </Flex>
          </StyledRadio>
        ))}
      </Flex>
    </Radio.Group>
  );
};

export default SecondLayer;
