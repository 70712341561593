import { LayoutDtoFirstLayerVariant } from 'api/requests/generated/generated.schemas';
import { Text } from 'components/Typography';
import useOnboarding from 'lib/hooks/useOnboarding';

import { Wall, Banner } from './FirstLayer';
import SecondLayer from './SecondLayer';
import { RelativeContainer, BackgroundContainer, PreviewTitleContainer, PrivacyTriggerWrapper } from './styled';
import { privacyButtonOptions } from '../Tabs/PrivacyTrigger/PrivacyButton';

const Preview = () => {
  const {
    customizationDetails: { styling, layout, privacyTrigger, isFirstLayerOpen, isSecondLayerOpen, setIsFirstLayerOpen },
  } = useOnboarding();

  return (
    <RelativeContainer>
      <PreviewTitleContainer>
        <Text type="secondary">Banner live customization preview</Text>
      </PreviewTitleContainer>
      <BackgroundContainer
        $background={styling.useOverlay ? styling.overlay : undefined}
        $opacity={styling.overlayOpacity}
      />

      {isFirstLayerOpen && (
        <>
          {layout.firstLayerVariant === LayoutDtoFirstLayerVariant.WALL && <Wall />}
          {layout.firstLayerVariant === LayoutDtoFirstLayerVariant.BANNER && <Banner />}
        </>
      )}

      {isSecondLayerOpen && <SecondLayer />}

      {!isFirstLayerOpen && !isSecondLayerOpen && (
        <PrivacyTriggerWrapper
          $background={privacyTrigger.privacyButtonBackgroundColor}
          onClick={() => setIsFirstLayerOpen(true)}
        >
          {privacyButtonOptions
            .find((privacyButton) => privacyButton.value === privacyTrigger.buttonPrivacyVariant)
            ?.icon(privacyTrigger.privacyButtonIconColor as string)}
        </PrivacyTriggerWrapper>
      )}
    </RelativeContainer>
  );
};

export default Preview;
