import { Switch } from 'antd';
import styled from 'styled-components';

import Button from 'components/Button';
import { Close } from 'components/Icons';
import { Text } from 'components/Typography';

export const BackgroundContainer = styled.div<{ $background?: string; $opacity?: number }>`
  width: 100%;
  height: 100%;
  background-color: ${(props) => props.$background || 'transparent'};
  opacity: ${(props) => props.$opacity};
  border-bottom: 1px solid ${(props) => props.theme.colors.blue[16]};
`;
export const PreviewTitleContainer = styled.div`
  border-bottom: 1px solid ${(props) => props.theme.colors.blue[16]};
  padding: 14px 32px;
`;

export const RelativeContainer = styled.div`
  position: relative;
  width: 100%;
  height: calc(100% - 50px);
`;

export const StyledButton = styled(Button)<{ $background?: string; $color?: string }>`
  width: 100%;
  font-size: 10px;
  background-color: ${(props) => props.$background};
  color: ${(props) => props.$color};
  padding: 8px;
  border: none;

  &&&:hover {
    background-color: ${(props) => props.$background};
    color: ${(props) => props.$color};
  }
`;

export const StyledText = styled(Text)<{ $opacity?: number }>`
  font-size: 10.5px;
  opacity: ${(props) => props.$opacity ?? 1};
`;

export const StyledSwitch = styled(Switch)<{ $background?: string; $iconColor?: string }>`
  background-color: ${(props) => props.$background};
  color: ${(props) => props.$iconColor};

  & .ant-switch-handle::before {
    background-color: ${(props) => props.$iconColor};
  }

  &.ant-switch-checked {
    background-color: ${(props) => props.$background};
  }

  & .anticon {
    svg {
      fill: ${(props) => props.$iconColor};
    }
  }

  &&:hover {
    background-color: ${(props) => props.$background};
    color: ${(props) => props.$iconColor};
    opacity: 0.8;
  }
`;

export const CloseIcon = styled(Close)`
  & svg {
    width: 15px;
    height: 15px;

    & path {
      fill: ${(props) => props.color};
    }
  }
`;

export const PrivacyTriggerWrapper = styled.div<{ $background?: string }>`
  position: absolute;
  bottom: -30px;
  left: 20px;
  width: 44px;
  height: 44px;
  background: ${(props) => props.$background};
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px;
  cursor: pointer;
`;
