import { CloseOutlined, CheckOutlined } from '@ant-design/icons';
import { Flex, Row, Col, Typography } from 'antd';

import { LegalFramework } from 'api/requests/generated/generated.schemas';
import Button from 'components/Button';
import { Text } from 'components/Typography';
import useOnboarding from 'lib/hooks/useOnboarding';

import { StyledLink, WallBanner } from './styled';
import { getInfoBySelectedFramework } from './utils';
import { StyledSwitch, StyledText, StyledButton, CloseIcon } from '../styled';

const Wall = () => {
  const {
    customizationDetails: { styling, setIsFirstLayerOpen, setIsSecondLayerOpen },
    selectedFramework,
  } = useOnboarding();

  const { description, title, links, buttons } = getInfoBySelectedFramework(selectedFramework);
  const isGDPR = selectedFramework === LegalFramework.GDPR;
  const isTcf = selectedFramework === LegalFramework.TCF2;

  return (
    <WallBanner vertical gap={10} justify="center" $background={styling.layerBackground}>
      <Flex vertical gap={4}>
        <Flex justify="space-between" align="center">
          <Text weight={500} size="md" color={styling.text}>
            {title}
          </Text>
          <Button ghost onClick={() => setIsFirstLayerOpen(false)} style={{ border: 'none' }}>
            <CloseIcon color={styling.text} />
          </Button>
        </Flex>

        <Typography.Paragraph style={{ fontSize: 11, margin: 0, color: styling.text }} ellipsis={{ rows: 9 }}>
          {description}
        </Typography.Paragraph>
      </Flex>

      <Flex gap={15}>
        {links.map((el) => (
          <StyledLink
            type="link"
            $color={styling.linkFont}
            key={el.title}
            onClick={() => {
              if (!el?.clickable) {
                return;
              }
              setIsSecondLayerOpen(true);
              setIsFirstLayerOpen(false);
            }}
          >
            {el.title}
          </StyledLink>
        ))}
      </Flex>

      <Row gutter={[12, 0]}>
        {!isTcf && !isGDPR && (
          <>
            <Col>
              <StyledSwitch
                size="small"
                unCheckedChildren={<CloseOutlined />}
                checkedChildren={<CheckOutlined />}
                checked={false}
                $background={styling.toggleInactiveBackground}
                $iconColor={styling.toggleInactiveIcon}
              />
            </Col>
            <Col span={9}>
              <Text size="sm" weight={600} color={styling.text} ellipsis={{ tooltip: true }}>
                Do not process my personal information
              </Text>
            </Col>
          </>
        )}
        {buttons.map((button) => (
          <Col span="12" key={`${selectedFramework}${[button]}`}>
            <StyledButton $background={styling.buttonBackgroundColor} $color={styling.buttonTextColor}>
              {button}
            </StyledButton>
          </Col>
        ))}
      </Row>
      <Flex justify="center">
        <StyledText size="sm" color={styling.text}>
          Powered by Usercentrics Consent Management
        </StyledText>
      </Flex>
    </WallBanner>
  );
};

export default Wall;
